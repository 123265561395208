import {Address} from './address';
import {OrderItem} from './order-item';

export class Order {
    account_id: number = null;
    location_id: number = null;
    // assoc_id: number = null;
    emailAddress: string = null;
    shippingAddress: Address = null;
    billingAddress: Address = null;
    items: OrderItem[] = [];
    paymentInformation: {} = null;
    shipToStore = false;
    subtotal = 0;
    estTax = 0;
    shipping = 0; // Logic regarding this will have to change. All calcs assume this is ZERO.
    total = 0;

    constructor() {
        if (this.items.length > 0) {
            this.calculateTotal();
        }
    }

    calculateSubtotal() {
        this.subtotal = this.items.reduce((sum, item) => sum + item.subtotal, 0);
    }

    calculateEstTax() {
        this.estTax = this.items.reduce((sum, item) => sum + item.estTax, 0);
    }

    calculateShipping() {
        this.shipping = this.items.reduce((sum, item) => sum + item.shipping, 0);
    }

    sumTotal() {
        // move this to a provider if it will be used more than once. DRY.
        function round(value: number) {
            return Number(Math.round(value * 100) / 100);
        }

        const total = this.subtotal + this.estTax + this.shipping;
        this.total = round(total);
    }

    calculateTotal() {
        this.calculateSubtotal();
        this.calculateEstTax();
        this.calculateShipping();
        this.sumTotal();
    }

    setEcomShippingCost() {
        if (this.total < 75) {
            this.shipping = 5;
            this.sumTotal();
        } else {
            this.shipping = 0;
            this.sumTotal();
        }
    }

    isComplete() {
        if (this.shippingAddress !== null
            && this.billingAddress !== null
            && this.paymentInformation !== null
            && this.emailAddress !== null
            && this.account_id !== null
            && this.location_id !== null
            // && this.assoc_id !== null
            && this.items.length > 0
            && this.total > 0
        ) {
            return true;
        } else {
            return false;
        }
    }
}
