export class Address {
    constructor(public firstName: string,
                public lastName: string,
                public phoneNumber: string,
                public address1: string,
                public address2: string,
                public city: string,
                public state: string,
                public zip: string) {

    }
}
