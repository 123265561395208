import {NgModule} from '@angular/core';
import {Environment} from './environment.token';
// import { productionConfig } from './production';
import {stagingConfig} from './staging';
import {developmentConfig} from './development';

declare const process: any;

export function environmentFactory() {
    switch (process.env.NODE_ENV) {
        case 'dev':
            return developmentConfig;
        case 'staging':
            return stagingConfig;
        // case 'prod':
        // 	return productionConfig;
        default:
            return developmentConfig;

    }
}

@NgModule({
    providers: [
        {
            provide: Environment,
            useFactory: environmentFactory
        }
    ]
})
export class EnvironmentsModule {
}
