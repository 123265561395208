import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {filter} from 'rxjs/operators';
import {ConfigStore} from '../state/config-store';
import {Api} from '../services/api/api';
import {EXC} from '../services/exc/exc';

import * as _ from 'underscore';
import {share} from 'rxjs/internal/operators';
import { IfStmt } from '@angular/compiler';
// import { _ } from 'underscore';
// import _ from 'underscore';

@Injectable()
export class ProductsStore {
    private _products: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _currentProducts: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _categories: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _colors: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _sizes: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _brands: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _genders: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _fits: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    private _currentProduct: BehaviorSubject<any> = new BehaviorSubject({});
    private _hasVariants: BehaviorSubject<Boolean> = new BehaviorSubject(false);

    private _account: number = null;
    private _accountBrands: any[] = null;
    public _paginate: any = {};
    public iscontainSizeFilter = false;

    constructor(public api: Api,
                private exc: EXC,
                private configStore: ConfigStore) {
        this.configStore.account.pipe(filter(account => account !== null)).subscribe(account => {
            this._account = account;
        });

        this.configStore.accountBrands.subscribe(accountBrands => {
            this._accountBrands = accountBrands;
        });
    }

    get products() {
        return this._products.asObservable();
    }

    get currentProducts() {
        return this._currentProducts.asObservable();
    }

    get categories() {
        return this._categories.asObservable();
    }

    get colors() {
        return this._colors.asObservable();
    }

    get sizes() {
        return this._sizes.asObservable();
    }

    get brands() {
        return this._brands.asObservable();
    }

    get genders() {
        return this._genders.asObservable();
    }

    get fits() {
        return this._fits.asObservable();
    }
    // get pagination(){
    //     return this._paginate;
    // }

    get currentProduct() {
        return this._currentProduct.asObservable();
    }

    get hasVariants() {
        return this._hasVariants.getValue();
    }

    findProducts(criteria, page_size, page, all_records, isFilter= false) {
        // set the thumbnail size based on device size in config function on start up
        // config needs to be set where app is locked if certain things like this aren't loaded
        const body: any = {
            current_account: criteria.current_account || this._account,
            brand: criteria.brand || null,
            accountBrands: this._accountBrands || null,
            categories: criteria.categories || null,
            search: criteria.search || null,
            filters: criteria.filters || null,
            variables: {
                thumbnailWidth: 500,
                thumbnailHeight: 500,
                largeThumbnailWidth: 500,
                largeThumbnailHeight: 500
            }
        };
        // let obs = this.api.post('products', body).share();
        const obs = this.api.post(`products?page_size=${page_size}&page=${page}&all_records=${all_records}&filterflag=${isFilter}`, body).pipe(share());

        obs.subscribe((data: any) => {
            const products = data.products;
            // this._paginate = data;
            this.setSearchableProperties(data.filters);
            this._products.next(products);
            this._currentProducts.next(products);
            // this.setSizes(data.filters.sizes);
        }, err => {
            this._products.error(err);
            this._currentProducts.error(err);
            this.exc.notifyUserOfError('Error Finding Products');
        });
        return obs;
    }

    setSearchableProperties(filters) {
        this.configStore.setfilter({filter: filters});
        this.setCategories(filters.categories);
        this.setColors(filters.colors);
        this.setSizes(filters.sizes);
        this.setBrands(filters.brands);
        this.setGenders(filters.genders);
        this.setFits(filters.fits);
    }

    setCurrentProducts(currentProducts) {
        this._currentProducts.next(currentProducts);
    }

    setCategories(categories) {
        // const categories = _.chain(products)
        //     .map(function (product) {
        //         return product.properties.category;
        //     })
        //     .flatten()
        //     .uniq()
        //     .map(function (product) {
        //         return {category: product};
        //     })
        //     .sortBy('category')
        //     .pluck('category')
        //     .without(null)
        //     .value();

            this._categories.next(categories);
    }
    setColors(colors) {
        // const colors =  _.chain(products)
        //     .map(function (product) {
        //         return {color: product.properties.color};
        //     })
        //     .pluck('color')
        //     .flatten()
        //     .uniq()
        //     .map(function (product) {
        //         return {color: product};
        //     })
        //     .sortBy('color')
        //     .pluck('color')
        //     .without(null)
        //     .value();

        this._colors.next(colors);
    }

    setSizes(sizes) {
        // const sizes = _.chain(products)
        //     .map(function (product) {
        //         return {
        //             size: product.properties.size
        //         };
        //     })
        //     .sortBy('size')
        //     .pluck('size')
        //     .without(null)
        //     .flatten()
        //     .uniq()
        //     .value();
        this._sizes.next(sizes);

    }

    setBrands(brands) {
        // const brands = _.chain(products)
        //     .map(function (product) {
        //         return product.properties.brand;
        //     })
        //     .flatten()
        //     .uniq()
        //     .map(function (product) {
        //         return {brand: product};
        //     })
        //     .sortBy('brand')
        //     .pluck('brand')
        //     .without(null)
        //     .value();
            this._brands.next(brands);
    }

    setGenders(genders) {
        // const genders = _.chain(products)
        //     .map(function (product) {
        //         return {gender: product.properties.gender};
        //     })
        //     .pluck('gender')
        //     .flatten()
        //     .uniq()
        //     .map(function (product) {
        //         return {gender: product};
        //     })
        //     .sortBy('gender')
        //     .pluck('gender')
        //     .without(null)
        //     .value();

        this._genders.next(genders);
    }

    setFits(fits) {
        // const fits = _.chain(products)
        //     .map(function (product) {
        //         return {fit: product.properties.fit};
        //     })
        //     .pluck('fit')
        //     .flatten()
        //     .uniq()
        //     .map(function (product) {
        //         return {fit: product};
        //     })
        //     .sortBy('fit')
        //     .pluck('fit')
        //     .without(null)
        //     .value();

            this._fits.next(fits);

    }
    filterSizeProducts(filters) {
        let filterCurrentProducts = [];
        let currentProducts = this._products.getValue();
        for (const filter of filters) {

            currentProducts.filter(product => {
            let isApply = false;
            if (filter.property === 'size') {
                this.iscontainSizeFilter = true;
                const sizes = product.properties.size || [];
                let pass = 0;
                for (const size of sizes) {
                    if (filter.value === size) {
                        pass++;
                    }
                }
                if (pass === 0) {
                    isApply = false;
                } else {
                    isApply = true;
                }
            }
            if (isApply) {
                filterCurrentProducts.push(product);
                currentProducts = this.arrayRemove(currentProducts, product);
            }
        });
    }
    if (filterCurrentProducts.length > 0) {
        this.setCurrentProducts(filterCurrentProducts);
        // this.setSearchableProperties(filterCurrentProducts);
    }
    }
    arrayRemove(arr, value) {

        return arr.filter(function(ele) {
            return ele != value;
        });
     }
    filterProducts(filters) {
        // const currentProducts = this._products.getValue().filter(product => {

            if (filters.length == 0) {
                return true;
            }

            let genderArray = [];

            genderArray = filters.filter(item => item.property == 'gender');

            let colorArray = [];

            colorArray = filters.filter(item => item.property == 'color');


            let sizeArray = [];
            sizeArray = filters.filter(item => item.property == 'size');

            let brandArray = [];
            brandArray = filters.filter(item => item.property == 'brand');

            let categoryArray = [];
            categoryArray = filters.filter(item => item.property == 'category');

            // var isGenderExists =  this.isPropertyExists(genderArray, product, 'gender');
            // var isColorExists = this.isPropertyExists(colorArray, product, 'color');
            // var isSizeExists = this.isPropertyExists(sizeArray, product, 'size');
            // var isBrandExists = this.isPropertyExists(brandArray, product, 'brand');
            // var isCategoriesExists = this.isPropertyExists(categoryArray, product, 'category');

            // if(isGenderExists && isColorExists && isSizeExists && isBrandExists && isCategoriesExists){
            //     return true;
            // }else{
            //     return false;
            // }


        // });

        this.filterSizeProducts(filters);
        // this.setCurrentProducts(currentProducts);
        // this.setSearchableProperties(currentProducts);
    }

    isPropertyExists(propertiesArray, product, propertyName) {

        if (propertiesArray.length <= 0) {
            return true;
        }

        const property = product.properties[propertyName] || [];

        if (propertiesArray.length > 0) {
            let check = false;
            for (const selectedProperty of propertiesArray) {

                if (property.indexOf(selectedProperty.value) != -1) {
                    check = true;
                }
            }

            return check;
        }

    }

    searchProducts(searchTerm) {
        let currentProducts = this._currentProducts.getValue();
        currentProducts = currentProducts.filter(product => {
            const productName = product.name.toLowerCase();
            const properties = {
                item_number: product.properties.item_number,
                upc: product.properties.upc
            };
            const propertiesString = JSON.stringify(properties).toLowerCase();
            return propertiesString.indexOf(searchTerm.toLowerCase()) > -1 || productName.indexOf(searchTerm.toLowerCase()) > -1;
        });
        this.setCurrentProducts(currentProducts);
        this.setSearchableProperties(currentProducts);
    }

    sortProducts(sortBy: { property: any; order: any; }) {
        const currentProducts = this._currentProducts.getValue();
        const property = sortBy.property;
        const order = sortBy.order;

        if (property === 'price') {
            currentProducts.sort((a, b) => {
                const valueA = a.retailPrice;
                const valueB = b.retailPrice;
                return valueA - valueB;
            });
        } else if (property === 'brand') {
            currentProducts.sort((a, b) => {
                const stringA = a.brand.name.toUpperCase();
                const stringB = b.brand.name.toUpperCase();
                return this.compareString(stringA, stringB);
            });
        } else if (property === 'name') {
            currentProducts.sort((a, b) => {
                const stringA = a.name.toUpperCase();
                const stringB = b.name.toUpperCase();
                return this.compareString(stringA, stringB);
            });
        }

        if (order === 'ASC') {
            currentProducts.reverse();
        }
    }

    findProduct(productID) {
        // set the thumbnail size based on device size in config function on start up
        // config needs to be set where app is locked if certain things like this aren't loaded

        const body: any = {
            productID: productID,
            variables: {
                imageWidth: 720,
                imageHeight: 720,
                thumbnailWidth: 240,
                thumbnailHeight: 240,
                largeThumbnailWidth: 500,
                largeThumbnailHeight: 500
            }
        };

        // let obs = this.api.post('product', body).share();
        const obs = this.api.post('product', body).pipe(share());

        obs.subscribe((data: any) => {
            const product = data;
            this._currentProduct.next(product);
            if (product.variants.length > 0) {
                this._hasVariants.next(true);
            } else {
                this._hasVariants.next(false);
            }
        }, err => {
            this._currentProduct.error(err);
            this.exc.notifyUserOfError('Error Finding Product');
        });

        return obs;
    }

    private compareString(stringA, stringB) {
        if (stringA < stringB) {
            return -1;
        }
        if (stringA > stringB) {
            return 1;
        }
        return 0;
    }
}
