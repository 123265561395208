import {IEnvironmentalVariables} from './variables';

export const developmentConfig: IEnvironmentalVariables = {
    // Development
    // mode: 'development',
    // apiUrl: 'http://localhost:1337/api/v2',
    // // apiUrl: 'https://exc-staging-pr-4.herokuapp.com/api/v2',
    // // apiUrl: 'https://staging.exchangecollective.com/api/v2',
    // appUrl: 'http://localhost:4200',
    // gaTrackerID: 'UA-110454945-2',
    // stripePK: 'pk_test_IJvzhY380X1G6jYnMsKrowbU'

    // Staging
    // mode: 'staging',
    // apiUrl: 'https://staging.exchangecollective.com/api/v2',
    // appUrl: 'https://beta.exchangecollective.com',
    // gaTrackerID: 'UA-110454945-3',
    // stripePK: 'pk_test_IJvzhY380X1G6jYnMsKrowbU'

    // Production
    mode: 'production',
    apiUrl: 'https://dashboard.exchangecollective.com/api/v2',
    appUrl: 'https://shop.exchangecollective.com',
    gaTrackerID: 'UA-110454945-2',
    stripePK: 'pk_live_h5uuLtKChEGXxo5nNNrM7h9v'
};
