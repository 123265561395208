import {Component} from '@angular/core';

import {Events, MenuController, NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {PreviousRouteService} from './services/previous-route/previous-route.service';
import {ConfigStore} from './state/config-store';
import {Subscription} from 'rxjs/index';
import {first} from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {

    categories: any[] = [];
    categoriesSubscription: Subscription;
    private accountType: number;
    private accountID: number;
    private secondaryID: number;
    private fullSecondaryID: string;
    private assocID: number;

    constructor(private platform: Platform,
                private splashScreen: SplashScreen,
                private statusBar: StatusBar,
                public events: Events,
                public menuCtrl: MenuController,
                public configStore: ConfigStore,
                private previousRoute: PreviousRouteService,
                private navCtrl: NavController) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            // alert('App component initializeApp');
            // this.navCtrl.navigateRoot(`home/3/813/6166`);

            this.events.subscribe('routeParams', (params) => {
                this.accountType = Number(params.accountType);
                this.accountID = Number(params.accountID);
                const fullSecondID = params.secondaryID.toString();
                this.fullSecondaryID = fullSecondID;
                this.parseSecondaryID(fullSecondID);
            });


            this.categoriesSubscription = this.configStore.mainCategories.subscribe(categories => {
                categories.sort((a, b) => {
                    const stringA = a.name.toUpperCase();
                    const stringB = b.name.toUpperCase();
                    return this.compareString(stringA, stringB);
                });
                this.categories = categories;
            });
        });
    }

    private compareString(stringA, stringB) {
        if (stringA < stringB) {
            return -1;
        }
        if (stringA > stringB) {
            return 1;
        }
        return 0;
    }

    async findProducts(categoryName, associatedCategoryName) {
        const criteriaTitle = categoryName.toUpperCase() + ' > ' + associatedCategoryName.toUpperCase();
        const categoryCriteria = [];
        // categoryCriteria.push(categoryName.toLowerCase());
        categoryCriteria.push(associatedCategoryName.toLowerCase());

        const title = criteriaTitle;
        this.menuCtrl.close();

        this.categories.forEach(elements => {
            elements.open = false;
        });

        if (this.assocID) {
            this.navCtrl.navigateForward(`products/${this.accountType}/${this.accountID}/${this.secondaryID}+${this.assocID}?categories=${categoryCriteria}&title=${title}`);
        } else {
            this.navCtrl.navigateForward(`products/${this.accountType}/${this.accountID}/${this.secondaryID}?categories=${categoryCriteria}&title=${title}`);
        }
    }

    parseSecondaryID(secondaryID: String) {
        if (secondaryID.includes('+')) {
            this.secondaryID = Number(secondaryID.split('+')[0]);
            this.assocID = Number(secondaryID.split('+')[1]);
        } else {
            this.secondaryID = Number(secondaryID);
        }
    }

    goToHome() {
        this.menuCtrl.close();
        if (this.assocID) {
            this.navCtrl.navigateRoot(`home/${this.accountType}/${this.accountID}/${this.secondaryID}+${this.assocID}`);
        } else {
            this.navCtrl.navigateRoot(`home/${this.accountType}/${this.accountID}/${this.secondaryID}`);
         }
    }

    toggleSection(i) {
        this.categories[i].open = !this.categories[i].open;

        for (let j = 0; j <= this.categories.length; j++) {
            if (j !== i) {
                if (this.categories[j]) {
                    this.categories[j].open = false;
                }
            }
        }
    }
}
