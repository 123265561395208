import Rollbar from 'rollbar'; 
import {Injectable, Inject, InjectionToken} from '@angular/core';
import {Subject, Observable} from 'rxjs';

import {Environment} from '../../../environments/environment.token';
import {IEnvironmentalVariables} from '../../../environments/variables';

const rollbarConfig = {
    accessToken: '79af875d6daa4c8ba2e391cb421ce3ca',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true
};

const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class EXC {
    private _excMessage: Subject<any> = new Subject();

    constructor(@Inject(RollbarService) public rollbar: Rollbar,
                @Inject(Environment) private ENV: IEnvironmentalVariables) {
        this.configure();
    }

    get excMessage(): Observable<string> {
        return this._excMessage.asObservable();
    }

    configure() {
        this.rollbar.configure({
            payload: {
                environment: this.ENV.mode || 'dev',
                source: 'ionic'
            }
        });
    }

    critical(err: any, data?: any): void {
        console.error('Critical Error: ', err);
        this.rollbar.critical(err);
    }

    error(err: any, data?: any): void {
        console.error('Error: ', err);
        this.rollbar.error(err);
    }

    warning(warning: any, data?: any): void {
        console.warn('Warning: ', warning);
        this.rollbar.warning(warning);
    }

    info(info: any, data?: any): void {
        this.rollbar.info(info, data);
    }

    debug(debug: any, data?: any): void {
        this.rollbar.debug(debug);
    }

    notifyUserOfError(message: string): void {
        this._excMessage.next(message);
        this.error('notifyUserOfError', {message: message});
    }

    notifyUserOfSuccess(message: string): void {
        this._excMessage.next(message);
    }
}
export function EXCRollbarFactory(environment:any) {
    const rb = new Rollbar(rollbarConfig);
    return new EXC(rb, environment);
}
