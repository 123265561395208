import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {CartModalPage} from './pages/cart-modal/cart-modal';
import {CheckoutModalPage} from './pages/checkout-modal/checkout-modal';
import {CartModalPageModule} from './pages/cart-modal/cart-modal.module';
import {CheckoutModalPageModule} from './pages/checkout-modal/checkout-modal.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home/:accountType/:accountID/:secondaryID',
        loadChildren: './pages/home/home.module#HomePageModule'
    },
    {
        path: 'brand/:accountType/:accountID/:secondaryID/:brandID',
        loadChildren: './pages/brand/brand.module#BrandPageModule'
    },
    {
        path: 'products/:accountType/:accountID/:secondaryID',
        loadChildren: './pages/products/products.module#ProductsPageModule'
    },
    {
        path: 'product/:accountType/:accountID/:secondaryID/:productID',
        loadChildren: './pages/product-modal/product-modal.module#ProductModalPageModule'
    },
    {
        path: 'cart/:accountType/:accountID/:secondaryID',
        loadChildren: './pages/cart-modal/cart-modal.module#CartModalPageModule'
    },
    {
        path: 'checkout/:accountType/:accountID/:secondaryID',
        loadChildren: './pages/checkout-modal/checkout-modal.module#CheckoutModalPageModule'
    },
    { path: 'not-found', component: NotFoundComponent }, 
    { path: '**', redirectTo: 'not-found' },
    {path: 'sample', loadChildren: './sample/sample.module#SamplePageModule'},
    {path: 'share', loadChildren: './share/share.module#SharePageModule'}
];

const routerOptions: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
