import {Injectable, Inject} from '@angular/core';
import { HttpClient } from '@angular/common/http';


import {Environment} from '../../../environments/environment.token';
import {IEnvironmentalVariables} from '../../../environments/variables';

/*
  Generated class for the ApiProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
    providedIn: 'root',
})
export class Api {
    url = 'http://localhost:1337/api/v2';
    // url = 'https://staging.exchangecollective.com/api/v2/';

    constructor(public http: HttpClient,
                @Inject(Environment) private ENV: IEnvironmentalVariables) {
        this.url = this.ENV.apiUrl;
    }

    get(endpoint: string, params?: any) {
        if (params) {
            const p = new URLSearchParams();
            for (const k in params) {
                if (k) {
                p.set(k, params[k]);
                }
            }
        }

        return this.http.get(this.url + '/' + endpoint);
    }

    post(endpoint: string, body: any) {
        return this.http.post(this.url + '/' + endpoint, body);
    }

}
