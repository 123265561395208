export class OrderItem {
    subtotal = 0;
    shipping = 0;
    estTax = 0;
    total = 0;

    constructor(public brand: any,
                public productID: number,
                public variantID: number,
                public name: string,
                public itemNumber: string,
                public upc: string,
                public size: string,
                public color: string,
                public images: string[],
                public thumbnails: string[],
                public quantityAvailable: number,
                public quantity: number,
                public retailPrice: number) {
        this.calculateSubtotal();
    }

    hasSufficientQuantity() {
        return this.quantity <= this.quantityAvailable;
    }

    calculateSubtotal() {
        this.subtotal = this.retailPrice * this.quantity;
        this.calculateEstTax();
    }

    calculateEstTax() {
        // move this to a provider if it will be used more than once. DRY.
        function round(value: number) {
            return Number(Math.round(value * 100) / 100);
        }

        const estTax = this.brand.taxRate * this.subtotal;
        this.estTax = round(estTax);

    }
}
