import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {IonicStorageModule} from '@ionic/storage';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {EXC, EXCRollbarFactory} from '../app/services/exc/exc';
import {Api} from '../app/services/api/api';
import {RollbarService, EXCErrorHandler, rollbarFactory} from '../app/services/error/error';
import {ConfigStore} from '../app/state/config-store';
import {ProductsStore} from '../app/state/products-store';
import {CartStore} from '../app/state/cart-store';

import {Environment} from '../environments/environment.token';
import {EnvironmentsModule} from '../environments/environment.module';

import {CartModalPage} from './pages/cart-modal/cart-modal';
import {CheckoutModalPage} from './pages/checkout-modal/checkout-modal';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {PreviousRouteService} from './services/previous-route/previous-route.service';
import * as $ from 'jquery';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CartModalPageModule } from './pages/cart-modal/cart-modal.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';



const rollbarConfig = {
    accessToken: '79af875d6daa4c8ba2e391cb421ce3ca',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true
};


@NgModule({
    declarations: [AppComponent,NotFoundComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        EnvironmentsModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        PhotoViewer,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: ErrorHandler, useClass: EXCErrorHandler},
        {provide: RollbarService, useFactory: rollbarFactory},
        Api,
        {provide: EXC, useFactory: EXCRollbarFactory, deps: [Environment]},
        ConfigStore,
        CartStore,
        EXCErrorHandler,
        ProductsStore,
        PreviousRouteService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
