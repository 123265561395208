import {Injectable, Injector, ErrorHandler, InjectionToken} from '@angular/core';
import Rollbar from 'rollbar';

import {Environment} from '../../../environments/environment.token';
import {IEnvironmentalVariables} from '../../../environments/variables';

/*
  Generated class for the ErrorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

const rollbarConfig = {
    accessToken: '79af875d6daa4c8ba2e391cb421ce3ca',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true
};

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
    providedIn: 'root',
})
export class EXCErrorHandler implements ErrorHandler {
    rollbar: Rollbar;
    ENV: IEnvironmentalVariables;

    constructor(private injector: Injector) {
        this.rollbar = injector.get(RollbarService);
        this.ENV = injector.get(Environment);
        this.rollbar.configure({
            payload: {
                environment: this.ENV.mode || 'dev',
                source: 'ionic'
            }
        });
    }

    handleError(err: any): void {
        console.error('EXCErrorHandler: ', err);
        this.rollbar.error(err.originalError || err);
    }
}
