import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Observable} from 'rxjs';
import {Api} from '../services/api/api';
import {Storage} from '@ionic/storage';
import {EXC} from '../services/exc/exc';

import * as _ from 'underscore';
import {share} from 'rxjs/internal/operators';

// import _ from 'underscore';


export interface HomeParams {
    accountType: string;
    accountID: string;
    secondaryID: string;
}

@Injectable()
export class ConfigStore {

    private _accountType: BehaviorSubject<number> = new BehaviorSubject(2);
    private _account: BehaviorSubject<number> = new BehaviorSubject(null);
    private _location: BehaviorSubject<number> = new BehaviorSubject(null);
    private _accountInfo: BehaviorSubject<any> = new BehaviorSubject(null);
    private _associate: BehaviorSubject<number> = new BehaviorSubject(null);
    private _associates: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    private _brands: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _accountBrands: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
    private _mainCategories: BehaviorSubject<Array<any>> = new BehaviorSubject([]);

    private _activated: BehaviorSubject<Boolean> = new BehaviorSubject(false);
    private _configured: BehaviorSubject<Boolean> = new BehaviorSubject(false);
    private _updated: Subject<any> = new Subject();

    constructor(public api: Api,
                private storage: Storage,
                private exc: EXC) {
        this.loadInitialData();
    }

    get accountType() {
        return this._accountType.asObservable();
    }

    get accountTypeValue() {
        return this._accountType.getValue();
    }

    get account() {
        return this._account.asObservable();
    }

    get accountValue() {
        return this._account.getValue();
    }

    get location() {
        return this._location.asObservable();
    }

    get locationValue() {
        return this._location.getValue();
    }

    get accountInfo() {
        return this._accountInfo.asObservable();
    }

    get associate() {
        return this._associate.asObservable();
    }

    get assocID() {
        return this._associate.getValue();
    }

    get associates() {
        return this._associates.asObservable();
    }

    get brands() {
        return this._brands.asObservable();
    }

    get accountBrands() {
        return this._accountBrands.asObservable();
    }

    get mainCategories() {
        return this._mainCategories.asObservable();
    }

    get activated(): Observable<Boolean> {
        return this._activated.asObservable();
    }

    get configured(): Observable<Boolean> {
        return this._configured.asObservable();
    }

    get updated() {
        return this._updated.asObservable();
    }

    loadInitialData() {
        this.storage.get('activationData').then(activationData => {
            if (activationData) {
                this.setConfigWithActivationData(activationData);
                this.resetConfig();
            }
        });
    }

    authenticate(activationData) {
        this.api.post('authenticate', activationData).subscribe((data: any) => {
            const result = data;
            if (result.success) {
                this.storage.set('token', result.token);
                this.storage.set('activationData', activationData).then(() => {
                    this.setConfigWithActivationData(activationData);
                    this.refreshLaunchData();
                });
            } else {
                this.exc.notifyUserOfError('Authentication failed. Please make sure you are using a valid URL. If this error continues, please contact Exchange Collective support at hello@exchangecollective.com');
                this.exc.error('Authentication Failed', {activationData: activationData});
            }
        }, this.exc.error);
    }

    setfilter(filters) {
        this.storage.set('filters', filters);
    }

    getfilter() {
        return this.storage.get('filters');
    }

    setConfigWithActivationData(activationData) {
        this._accountType.next(activationData.accountType);
        this._account.next(activationData.accountID);
        this._location.next(activationData.secondaryID);
        if (activationData.accountType === 4 || activationData.accountType === 3) {
            this._associate.next(activationData.assocID);
        }
        this._activated.next(true);
    }

    setConfigWithLaunchData(launchData) {
        this._brands.next(launchData.brands);
        this._accountBrands.next(_.pluck(launchData.brands, 'id'));
        this._mainCategories.next(launchData.categories);
        this._associates.next(launchData.salesAssociates);
        this._accountInfo.next(launchData.accountInfo);
        this._configured.next(true);
        this._updated.next('updated');
    }

    refreshLaunchData() {
        const body = {
            account_id: this._account.getValue() || null,
            variables: {
                logoWidth: 280,
                logoHeight: 280
            }
        };

        this.storage.get('launchData')
            .then(data => {
                if (data) {
                    if (data.accountID && data.accountID == this._account.getValue()) {
                        data.accountID = this._account.getValue();
                        const launchData = data;
                        this.storage.set('launchData', launchData);
                        this.setConfigWithLaunchData(launchData);
                    } else {
                        this.relaunchDataPostRequest(body);
                    }
                } else {
                    this.relaunchDataPostRequest(body);
                }
            })
            .catch(err => {
                this.relaunchDataPostRequest(body);
            });

    }

    relaunchDataPostRequest(body) {
        this.api.post('launchData', body).subscribe((data: any) => {
            data.accountID = body.account_id;
            const launchData = data;
            this.storage.set('launchData', launchData);
            this.setConfigWithLaunchData(launchData);
        }, this.exc.error);
    }

    resetConfig() {
        this.storage.get('launchData').then(launchData => {
            if (launchData) {
                this.setConfigWithLaunchData(launchData);
                this.refreshLaunchData();
                /* Stale-While-Revalidate */
            } else {
                this.refreshLaunchData();
            }
        }).catch(this.exc.error);
    }

    findBrand(brandID) {
        // set the thumbnail size based on device size in config function on start up
        const body: any = {
            brandID: brandID || null,
        };

        // let obs = this.api.post('brand', body).share();
        const obs = this.api.post('brand', body).pipe(share());

        obs.subscribe(data => {
            // let categories = data.json();
            // this._products.next(products);
            // this._currentProducts.next(products);
        }, err => {
            // this._products.error(err);
            // this._currentProducts.error(err);
            this.exc.notifyUserOfError('Error Loading Brand');
        });

        return obs;
    }

    rebuildHomeParams() {
        return new Promise((resolve, reject) => {
            this.storage.get('activationData').then(activationData => {
                if (activationData) {
                    const homeParams: HomeParams = {
                        accountType: activationData.accountType,
                        accountID: activationData.accountID,
                        secondaryID: activationData.secondaryID,
                    };
                    if (activationData.assocID) {
                        homeParams.secondaryID = activationData.secondaryID + '+' + activationData.assocID;
                    }
                    resolve(homeParams);
                } else {
                    resolve(null);
                }
            });
        });
    }
}