import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ConfigStore} from '../state/config-store';
import {Api} from '../services/api/api';
import {Order} from '../models/order';
import {OrderItem} from '../models/order-item';
import {Address} from '../models/address'; // switch model --> interface
import {EXC} from '../services/exc/exc';
import {take} from 'rxjs/operators';
import {Storage} from '@ionic/storage';

export interface CheckoutResult {
    success: string;
    message: string;
    orderID: string;
}

@Injectable()
export class CartStore {
    private _order: BehaviorSubject<Order> = new BehaviorSubject(null);
    private _items: BehaviorSubject<Array<OrderItem>> = new BehaviorSubject([]);
    private _shippingIsBillingAddress: BehaviorSubject<Boolean> = new BehaviorSubject(false);

    constructor(public api: Api,
                public configStore: ConfigStore,
                public localStorage: Storage,
                private exc: EXC) {

        let order = new Order();
        this._order.next(order);

        this.localStorage.ready().then(res => {
            this.localStorage.get('order').then(localOrder => {

                if (localOrder) {
                    order = Object.assign(order, localOrder);
                    if (localOrder.items && localOrder.items.length) {
                        let items = this._items.getValue();

                        items = [];

                        order.items.forEach(product => {
                            const orderItem: OrderItem = new OrderItem(
                                product.brand,
                                product.productID,
                                product.variantID,
                                product.name,
                                product.itemNumber,
                                product.upc,
                                product.size,
                                product.color,
                                product.images,
                                product.thumbnails,
                                product.quantityAvailable,
                                product.quantity,
                                product.retailPrice);
                            items.push(orderItem);
                        });
                        order.items = items;
                        this._items.next(items);
                    }
                }
                this._order.next(order);

            });
        });


        this.configStore.account.subscribe(account => {
            // const orderObs = this._order.getValue();
            // orderObs.account_id = account;
            // this.localStorage.set('order', orderObs).then(data => {
            //     this._order.next(orderObs);
            // });

            this.isSameStore().then((orderObs: Order) => {
                // const orderObs = this._order.getValue();
                orderObs.account_id = account;
                this.localStorage.set('order', orderObs).then(data => {
                    this._order.next(orderObs);
                });
            });
        });

        this.configStore.location.subscribe(location => {
            // const orderObs = this._order.getValue();
            // order.location_id = location;
            // this.localStorage.set('order', orderObs).then(data => {
            //     this._order.next(orderObs);
            // });

            this.isSameStore().then((orderObs: Order) => {
                // const orderObs = this._order.getValue();
                order.location_id = location;
                this.localStorage.set('order', orderObs).then(data => {
                    this._order.next(orderObs);
                });

            });

        });


        this.configStore.associate.subscribe(associate => {
            // const orderObs = this._order.getValue();
            // orderObs.assoc_id = associate;
            // this.localStorage.set('order', orderObs).then(data => {
            //     this._order.next(orderObs);
            // });


            this.isSameStore().then((orderObs: Order) => {
                // const orderObs = this._order.getValue();
                // orderObs.assoc_id = associate;
                this.localStorage.set('order', orderObs).then(data => {
                    this._order.next(orderObs);
                });
            });

        });
    }

    get order() {
        return this._order.asObservable();
    }

    get orderValue() {
        return this._order.getValue();
    }

    get items() {
        return this._items.asObservable();
    }

    get shippingIsBillingAddress() {
        return this._shippingIsBillingAddress.asObservable();
    }

    resetCart() {
        const order = new Order();

        this.configStore.account.pipe(take(1)).subscribe(account => {
            order.account_id = account;
            this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            });
        });
        this.configStore.location.pipe(take(1)).subscribe(location => {
            order.location_id = location;
            this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            });
        });
        // this.configStore.associate.pipe(take(1)).subscribe(associate => {
        //     order.assoc_id = associate;
        //     this.localStorage.set('order', order).then(data => {
        //         this._order.next(order);
        //     });
        // });

        order.items = [];
        this.localStorage.set('order', order).then(data => {
            this._order.next(order);
        });
        this.localStorage.remove('isSameAsShipping');

        this._items.next([]);
    }

    addToCart(item: OrderItem) {
        const order = this._order.getValue();
        item.calculateSubtotal();
        // order.items.push(Object.assign({}, item));
        order.items.push(item);
        order.calculateTotal();
        if (this.configStore.accountTypeValue === 3) {
            order.setEcomShippingCost();
        }


        // const items = this._items.getValue();
        // items.push(item);

        let items = this._items.getValue();
        items = order.items;

        this._items.next(items);

        this.localStorage.set('order', order).then(data => {
            this._order.next(order);
        });
    }

    changeItemQuantity(item: OrderItem, increment) {
        item.quantity += increment;
        item.calculateSubtotal();

        const order = this._order.getValue();
        order.calculateTotal();
        if (this.configStore.accountTypeValue === 3) {
            order.setEcomShippingCost();
        }
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    removeFromCart(item: OrderItem) {
        const order = this._order.getValue();
        const orderItems = order.items;
        const indexToRemove1 = orderItems.indexOf(item);
        orderItems.splice(indexToRemove1, 1);
        order.calculateTotal();
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );

        const items = this._items.getValue();
        const indexToRemove2 = items.indexOf(item);
        if (indexToRemove2 > -1) {
            items.splice(indexToRemove2, 1);
            this._items.next(items);
        }
    }

    setShippingAddress(address: Address) {
        const newAddress = new Address(
            address.firstName,
            address.lastName,
            address.phoneNumber,
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.zip,
        );
        const order = this._order.getValue();
        order.shippingAddress = newAddress;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    setBillingAddress(address: Address) {
        const newAddress = new Address(
            address.firstName,
            address.lastName,
            address.phoneNumber,
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.zip,
        );
        const order = this._order.getValue();
        order.billingAddress = newAddress;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    setEmailAddress(email) {
        const order = this._order.getValue();
        order.emailAddress = email;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    setPayment(paymentInfo) {
        const order = this._order.getValue();
        order.paymentInformation = paymentInfo;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    setAssociate(assocID) {
        const order = this._order.getValue();
        // order.assoc_id = assocID;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    // Update
    setShipToStore() {
        const order = this._order.getValue();
        order.shipToStore = true;
        this._order.next(order);
        // below is for testing purposes
        const address = {
            firstName: 'Dan',
            lastName: 'Pankratz',
            phoneNumber: '8055985151',
            address1: '4854 S Bradley Rd',
            address2: 'Unit 107',
            city: 'Santa Maria',
            state: 'CA',
            zip: '93455'
        };
        this.setShippingAddress(address);
    }

    unsetShipToStore() {
        const order = this._order.getValue();
        order.shipToStore = false;
        order.shippingAddress = null;
        this._order.next(order);
    }

    setShippingAsBillingAddress() {
        const order = this._order.getValue();
        order.billingAddress = order.shippingAddress;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    unsetShippingAsBillingAddress() {
        const order = this._order.getValue();
        order.billingAddress = null;
        this.localStorage.set('order', order).then(data => {
                this._order.next(order);
            }
        );
    }

    submitOrder(): Promise<CheckoutResult> {
        debugger;
        return new Promise((resolve, reject) => {
            if (this._order.getValue().isComplete()) {
                const body = this._order.getValue();
                this.api.post('order', body).subscribe((data: any) => {
                        const checkoutResult: CheckoutResult = data;
                        this.exc.info('checkout result: ', checkoutResult);
                        if (checkoutResult.success) {
                            this.exc.notifyUserOfSuccess(checkoutResult.message);
                            // this.resetCart();
                            resolve(checkoutResult);
                        } else {
                            this.exc.notifyUserOfError(checkoutResult.message);
                            reject(checkoutResult);
                        }
                    }, err => {
                        this.exc.error('An error occured during the processing of the order.', err);
                        this.exc.notifyUserOfError('An error occured during the processing of the order');
                        reject({
                            success: false,
                            message: 'An error occured during the processing of the order.',
                            orderID: null
                        });
                    }
                );
            } else {
                this.exc.error('Order could not be submitted because it was not completed.', this._order.getValue());
                this.exc.notifyUserOfError('Order could not be submitted because it was not completed.');
                reject({
                    success: false,
                    message: 'Order could not be submitted because it was not completed.',
                    orderID: null
                });
            }
        });
    }

    isSameStore() {

        // let orderObs = this._order.getValue();
        // order.location_id = location;
        // this.localStorage.set('order', orderObs).then(data => {
        //     this._order.next(orderObs);
        // });


        return new Promise((resolve, reject) => {
            const orderObs = this._order.getValue();
            this.localStorage.get('activationData').then(activationData => {
                this.localStorage.get('order').then(localOrder => {


                    if (localOrder) {
                        if (activationData.accountID !== localOrder.account_id || activationData.secondaryID !== localOrder.location_id || activationData.assocID !== localOrder.assoc_id) {
                            orderObs.emailAddress = null;
                            orderObs.shippingAddress = null;
                            orderObs.billingAddress = null;
                            orderObs.items = [];
                            orderObs.paymentInformation = null;
                            orderObs.subtotal = 0;
                            orderObs.estTax = 0;
                            orderObs.shipping = 0;
                            orderObs.total = 0;
                            this._items.next([]);

                            // this.localStorage.set('order', order).then(() => {
                            //     this._order.next(order);
                            // });
                            this.localStorage.remove('isSameAsShipping');
                            resolve(orderObs);
                        } else {
                            resolve(orderObs);
                        }
                    } else {
                        resolve(orderObs);
                    }
                })
                    .catch(() => {
                        resolve(orderObs);
                    });
            })
                .catch(() => {
                    resolve(orderObs);
                });
        });
    }

}
